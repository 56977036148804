var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"350","tile":""}},[(_vm.loadingPremiumList)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item, divider, list-item, divider, list-item, divider,list-item, divider, list-item, divider, list-item, divider"}}):_c('v-list',{class:[!_vm.selectedItem ? 'product-options-list' : ''],attrs:{"max-height":"380"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('products_options.list.title')))]),_vm._l((_vm.filterItems),function(item){return _c('v-list-item-group',{key:item.id,class:['product-options-premium-item', _vm.highlight(item.id)],attrs:{"max":1,"value":_vm.selectedItemId}},[_c('v-list-item',{class:[
          _vm.selectedItemId === item.id ? 'v-list-item--active' : '',
          'qa-product-options__premium-item',
        ],on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-list-item-title',[(_vm.selectedItemId === item.id)?_c('v-chip',{staticClass:"single-color"},[_vm._v(" 1 ")]):_vm._e(),_vm._v(" "+_vm._s(item.title)+" ")],1),(_vm.selectedItemId === item.id)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()],1)],1)})],2),(_vm.selectedItem && _vm.loadingSecondaryList)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item, divider, list-item, divider, list-item, divider,list-item, divider, list-item, divider, list-item, divider"}}):_vm._e(),(!_vm.loadingSecondaryList && _vm.selectedItem)?_c('v-list',{staticClass:"product-options-list",attrs:{"dense":"","max-height":"267"}},_vm._l((_vm.secondaryItems),function(secondaryItem){return _c('v-list-item-group',{key:secondaryItem.id,class:[
        'product-options-premium-item',
        _vm.highlightSecondary(secondaryItem.id),
      ],attrs:{"dense":"","max":1,"value":_vm.selectedSecondaryItemId}},[_c('v-list-item',{class:[
          _vm.selectedSecondaryItemId === secondaryItem.id
            ? 'v-list-item--active'
            : '',
          'qa-product-options__secondary-item',
        ],on:{"click":function($event){return _vm.selectSecondaryItem(secondaryItem)}}},[_c('v-list-item-title',[(_vm.selectedSecondaryItemId === secondaryItem.id)?_c('v-chip',{staticClass:"mix-color",attrs:{"small":""}},[_vm._v(" 2 ")]):_vm._e(),_vm._v(" "+_vm._s(secondaryItem.title)+" ")],1)],1)],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }