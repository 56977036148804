<template>
  <v-dialog v-model="dialog" :persistent="saving" width="700">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <v-card>
      <v-toolbar class="headline blue-grey darken-2 white--text">
        <span>
          {{ isEditing ? '' : $t('aplicacao.nova_aplicacao') }}
        </span>
        <v-spacer />
        <v-tooltip bottom class="pr-1">
          <template #activator="{ on }">
            <div v-on="on">
              <v-icon class="white--text">mdi-help-circle-outline</v-icon>
            </div>
          </template>
          <div>
            {{ $t('fungicida.atualiza_eficacia') }}
          </div>
        </v-tooltip>
        <v-btn icon dark @click="dialog = false" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pb-0">
        <v-row justify="center" align="center" dense>
          <v-col class="text-center title">
            {{ num_aplicacao }}ª {{ $t('aplicacao.aplicacao').toLowerCase() }}
          </v-col>
        </v-row>
        <v-divider />
        <v-alert
          v-if="isWhiteMoldOnlySpraying"
          class="mt-4"
          icon="mdi-information"
          prominent
          text
          type="warning"
          width="100%"
        >
          <span
            v-html="$t('diseases.alert.white_mold_specific_spraying')"
          ></span>
        </v-alert>
        <v-alert
          v-if="hasWhiteMold"
          class="mt-4"
          icon="mdi-information"
          prominent
          text
          type="warning"
          width="100%"
        >
          <span v-html="$t('diseases.alert.white_mold_mix_spraying')"></span>
        </v-alert>
        <v-form ref="form" v-model="isValidForm">
          <v-row>
            <v-col class="text-sm-center">
              <span class="caption">
                {{ $t('aplicacao.doencas_prioridade.select_label') }}
              </span>
              <v-skeleton-loader
                type="text"
                class="mx-auto"
                :loading="loadingDoencasPrioridades"
              >
                <v-chip-group
                  :max="numberOfTargets"
                  @change="changePriorityDisease"
                  multiple
                  ref="doencasChipGroup"
                  v-model="priorityDiseasesIndexes"
                  column
                  class="d-inline-flex justify-sm-center"
                >
                  <v-chip
                    v-for="disease in cropDiseasesFiltered"
                    :key="disease.id"
                    :active-class="doencaChipColor(disease)"
                    small
                    :disabled="disableTargetsSelectionByRules(disease.id)"
                  >
                    {{ $t(`diseases.${disease.code}`) }}
                  </v-chip>
                </v-chip-group>
                <div class="caption text-left text-sm-center">
                  <v-chip x-small color="red">&nbsp;</v-chip>
                  {{ $t('priority') }}
                  <v-chip x-small color="amber" class="ml-2">&nbsp;</v-chip>
                  {{ $t('secondary') }}
                </div>
              </v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row
            dense
            v-for="(produto, i) in selectedProducts"
            :key="`${produto.nome}-${i}`"
            align="center"
            justify="center"
          >
            <v-col class="d-none d-sm-inline-block" cols="2" sm="1">
              <dosis-tooltip :produto="produto"></dosis-tooltip>
            </v-col>
            <v-col cols="10" sm="4">
              <v-autocomplete
                v-model="produto.id"
                color="white"
                item-text="nome"
                item-value="id"
                required
                :filter="customDefensiveFilter"
                :items="filteredDefensives"
                :label="$t('pesticide.pesticide')"
                :rules="autocompleteRules"
                @change="selectProduct(produto, i)"
              >
                <template #item="data">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex justify-space-between">
                      <span>{{ data.item.nome }}</span>
                      <template
                        v-if="hasWhiteMold || prioritaryTargetIsWhiteMold"
                      >
                        <v-chip
                          v-for="acronym in data.item.special_targets"
                          :key="acronym"
                          class="blue-grey darken-2"
                          text-color="white"
                          x-small
                        >
                          <b>{{ acronym }}</b>
                        </v-chip>
                      </template>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="d-block d-sm-none text-center" cols="2">
              <dosis-tooltip :produto="produto"></dosis-tooltip>
            </v-col>
            <v-col cols="5" sm="3" align="center">
              <v-text-field
                v-model="produto.dose"
                :label="unitLabel(produto)"
                :rules="doseRules"
                required
                v-money="maskConfig"
              ></v-text-field>
            </v-col>
            <v-col cols="5" sm="3" align="center">
              <v-text-field
                v-model="produto.preco"
                :rules="precoRules"
                :label="priceLabel(produto)"
                required
                v-money="maskConfig"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="1" class="text-center">
              <v-btn @click="excluirProduto(i)" icon color="red" small>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col class="text-center text-sm-right" cols="12" sm="4">
            <v-btn @click="novoFungicida" small>
              + {{ $t('fungicida.fungicida') }}
            </v-btn>
          </v-col>
          <v-col class="text-center" cols="12" sm="8" v-if="showProductOptions">
            <v-btn
              class="text-caption text-sm-body-2"
              text
              @click="toggleSuggestions"
              small
              v-if="$vuetify.breakpoint.xs"
            >
              {{ openSuggestionsBtn.short_title }}
              <v-icon right> {{ openSuggestionsBtn.icon }} </v-icon>
            </v-btn>
            <v-btn
              class="text-caption text-sm-body-2"
              text
              @click="toggleSuggestions"
              small
              v-else
            >
              {{ openSuggestionsBtn.title }}
              <v-icon right> {{ openSuggestionsBtn.icon }} </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <product-options-container
        v-if="showProductOptions"
        v-show="openSuggestions"
        @choosing="addMixToApplication"
        :diseases-data="diseasesData"
      />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-1" text @click="close" :disabled="saving">
          {{ $t('cancelar') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="salvar"
          :loading="saving"
          :disabled="
            loadingDoencasPrioridades || !doencaPrioritariaId || !isValidForm
          "
        >
          {{ $t('salvar') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin.vue'
import DosisTooltip from '@/components/Spraying/DosisTooltip.vue'
import ProductOptionsMixin from '@/components/ProductOptions/ProductOptionsMixin.vue'
import productPriceStorage from '@/services/storage/productPriceStorage'
import WhiteMoldMixin from '@/components/Spraying/WhiteMoldMixin.vue'
import { mapActions, mapGetters } from 'vuex'
import { events, logEvent } from '@/services/analytics'

export default {
  name: 'SprayingModal',

  mixins: [CropDiseasesMixin, ProductOptionsMixin, WhiteMoldMixin],

  components: {
    DosisTooltip,
  },

  props: {
    planningProgram: { type: Object, default: null },
    aplicacao: { type: Number, required: true },
  },

  data() {
    return {
      dialog: false,
      num_aplicacao: this.aplicacao,
      selectedProducts: [{ unidade: 'L', tipo: '', preco: '', dose: '' }],
      priorityDiseasesIndexes: [],
      doencaPrioritariaId: null,
      doencaSecundaria1Id: null,
      doencaSecundaria2Id: null,
      isValidForm: false,
      autocompleteRules: [(v) => !!v || this.$t('fungicida.selecione_produto')],
      doseRules: [
        (v) =>
          (v && this.removeMask(v) > 0) || this.$t('fungicida.informe_dose'),
      ],
      precoRules: [
        (v) =>
          (v && this.removeMask(v) > 0) || this.$t('fungicida.informe_preco'),
      ],
      maskConfig: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      saving: false,
      loadingDoencasPrioridades: false,
      savedProducts: [],
    }
  },

  mounted() {
    if (this.isEditing) {
      this.selectedProducts = this.sprayingsDefensives?.map((p) => ({
        id: p.defensivo.id,
        nome: p.defensivo.nome,
        dose: p.dose.toFixed(2),
        preco: p.preco.toFixed(2),
        tipo: p.defensivo.tipo,
        unidade: p.defensivo?.unidade_medida?.replace('_', ' '),
      }))

      this.savedProducts = JSON.parse(JSON.stringify(this.selectedProducts))
      this.setPrioritariesTargets(
        this.selectedSpray.doenca_prioritaria_id,
        this.selectedSpray.doenca_secundaria_1_id
      )
      this.setWhiteMoldOnlySpraying(
        this.selectedSpray.doenca_prioritaria_id,
        this.selectedSpray.doenca_secundaria_1_id
      )
    }
  },

  watch: {
    dialog() {
      if (this.filteredDefensives && this.selectedProducts) {
        this.updateSelectedProducts()
      }
      if (this.doencaPrioritariaId) {
        this.updatePriorityDiseaseIndexForTabs()
      }
      if (!this.isEditing) {
        this.resetProdutos()
        this.refreshTargetsSuggestion()
      }
    },
  },

  computed: {
    ...mapGetters('execucoes', ['programa', 'execucoes']),
    ...mapGetters('crops', ['isSoybean']),
    ...mapGetters('defensives', ['defensives']),
    ...mapGetters('user', ['isUSA']),
    filteredDefensives() {
      const selectedDefensives = this.isWhiteMoldOnlySpraying
        ? this.whiteMoldDefensives
        : this.defensives
      return selectedDefensives?.map((defensive) => {
        const special_targets = []
        if (
          defensive?.is_white_mold &&
          defensive?.doses?.soybean_white_mold?.has_register
        ) {
          special_targets.push(this.$t('diseases.soybean_white_mold').acronym())
        }
        return {
          ...defensive,
          special_targets,
        }
      })
    },
    program() {
      if (this.planningProgram) return this.planningProgram
      return this.programa
    },
    sprayings() {
      if (this.planningProgram) return this.planningProgram?.planejamentos ?? []
      return this.execucoes
    },
    isEditing() {
      return this.aplicacao <= this.sprayings.length
    },
    selectedSpray() {
      return this.sprayings[this.aplicacao - 1]
    },
    sprayingsDefensives() {
      if (!this.sprayings) {
        return []
      }

      if (this.planningProgram)
        return this.selectedSpray?.planejamento_defensivos ?? []

      return this.selectedSpray?.execucao_defensivos
    },
    API() {
      const api = this.planningProgram
        ? this.$api.programas.planejamentos
        : this.$api.programas.execucoes

      return this.isEditing ? api.update : api.create
    },
    numberOfTargets() {
      return process.env.VUE_APP_MAX_DOENCAS_PRIORIDADES
    },
    showProductOptions() {
      return (
        this.$toggle('sugestao') &&
        this.isSoybean &&
        !this.prioritaryTargetIsWhiteMold &&
        !this.isUSA
      )
    },
  },

  methods: {
    ...mapActions('execucoes', ['loadPrograma']),
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    customDefensiveFilter(item, queryText) {
      const text = this.removeAccents(item.nome.toLowerCase())
      const searchText = this.removeAccents(queryText.toLowerCase())

      return text.includes(searchText)
    },
    setPrioritariesTargets(prioritaryTargetId, secondaryTargetId) {
      this.doencaPrioritariaId = prioritaryTargetId
      this.doencaSecundaria1Id = secondaryTargetId
    },
    async refreshTargetsSuggestion() {
      const include = ['data_aplicacao']
      if (this.program.doencas_sugeridas && !this.doencaPrioritariaId) {
        include.push('doencas')
        this.loadingDoencasPrioridades = true
      }
      try {
        const response = await this.$api.programas.sugerir(
          this.program,
          include,
          this.num_aplicacao,
          this.doencaPrioritariaId
        )
        this.loadingDoencasPrioridades = false
        const { doencas } = response.data
        if (doencas) {
          this.program.doencas_sugeridas = true
          this.setPrioritariesTargets(
            doencas.prioritaria_id,
            doencas.secundaria_1_id
          )
          this.setWhiteMoldOnlySpraying(
            doencas.prioritaria_id,
            doencas.secundaria_1_id
          )
        }
        this.updatePriorityDiseaseIndexForTabs()
      } catch (error) {
        this.loadingDoencasPrioridades = false
        this.$root.$emit('notify', 'error', this.$t('aplicacao.sugerir.erro'))
      }
    },
    updatePriorityDiseaseIndexForTabs() {
      const selectedDiseasesIds = [
        this.doencaPrioritariaId,
        this.doencaSecundaria1Id,
      ]
      this.priorityDiseasesIndexes = selectedDiseasesIds
        .map((diseaseId) =>
          this.cropDiseasesFiltered?.findIndex(({ id }) => id == diseaseId)
        )
        .filter((value) => value >= 0)
    },
    changePriorityDisease(prioritizedDiseasesIndex) {
      const [doencaPrioritariaId = null, doencaSecundaria1Id = null] =
        prioritizedDiseasesIndex.map(
          (index) => this.cropDiseasesFiltered[index]?.id
        )
      this.program.doencas_sugeridas =
        this.doencaPrioritariaId == doencaPrioritariaId &&
        this.doencaSecundaria1Id == doencaSecundaria1Id

      this.doencaPrioritariaId = doencaPrioritariaId
      this.doencaSecundaria1Id = doencaSecundaria1Id
    },
    doencaChipColor(doenca) {
      switch (doenca.id) {
        case this.doencaPrioritariaId:
          return 'red white--text'
        case this.doencaSecundaria1Id:
          return 'amber black--text'
        case this.doencaSecundaria2Id:
          return 'amber black--text'
        default:
          return ''
      }
    },
    novoFungicida() {
      this.selectedProducts.push({
        unidade: 'L',
        tipo: '',
        preco: '',
        dose: '',
      })
    },
    excluirProduto(i) {
      this.selectedProducts.splice(i, 1)
    },
    getProductDataFromList(defensive) {
      const list = this.filteredDefensives
      const foundDefensive = list.find((p) => p.id == defensive.id)
      return Object.assign(defensive, foundDefensive)
    },
    selectProduct(product, i) {
      const completeProduct = this.getProductDataFromList(product)
      completeProduct.preco = completeProduct?.lastPrice
        ? Number(completeProduct?.lastPrice).toFixed(2)
        : 0.0
      completeProduct.dose = completeProduct?.lastDose
        ? Number(completeProduct?.lastDose).toFixed(2)
        : 0.0
      if (this.isEditing) {
        const savedProduct = this.savedProducts.find(
          (p) => p.id == completeProduct.id
        )
        if (savedProduct) {
          completeProduct.preco = savedProduct.preco
          completeProduct.dose = savedProduct.dose
        }
      }
      this.$set(this.selectedProducts, i, completeProduct)
    },
    updateSelectedProducts() {
      for (var i = this.selectedProducts.length - 1; i >= 0; i--) {
        this.selectProduct(this.selectedProducts[i], i)
      }
    },
    removeMask(valor) {
      return parseFloat(valor.split('.').join('').split(',').join('.'))
    },
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    async salvar() {
      if (!this.$refs.form.validate()) {
        return
      }
      try {
        this.saving = true
        const { data } = await this.API(this.mountSprayingData())
        if (!this.planningProgram) this.loadPrograma()
        if (!this.isEditing) this.resetProdutos()
        const { notifications } = data
        if (notifications && !this.isUSA)
          this.$root.$emit('notifyArray', notifications)
        productPriceStorage(this.selectedProducts).save()
        this.savedProducts = JSON.parse(JSON.stringify(this.selectedProducts))
        this.$emit('alterou')
        this.saving = false
        logEvent(events.programs.clickedButton_createdSpraying)
        this.close()
      } catch (error) {
        this.saving = false
        throw error
      }
    },
    mountSprayingData() {
      const id = this.isEditing ? this.selectedSpray.id : null
      return {
        id: id,
        programa_id: this.program.id,
        aplicacao: this.aplicacao,
        adjuvantes: [],
        foliares: [],
        defensivos: this.selectedProducts.map((p) => ({
          defensivo_id: p.id,
          dose: this.removeMask(p.dose).toFixed(2),
          preco: this.removeMask(p.preco).toFixed(2),
        })),
        doencas_sugeridas: this.program.doencas_sugeridas,
        doenca_prioritaria_id: this.doencaPrioritariaId,
        doenca_secundaria_1_id: this.doencaSecundaria1Id,
      }
    },
    resetProdutos() {
      this.selectedProducts = [
        {
          unidade: 'L',
          tipo: '',
          dose: 0,
          preco: 0,
        },
      ]
    },
    unitLabel(produto) {
      return (
        (produto?.unidade?.replace('_', ' ') ||
          this.$unitMeasures['volume'][this.$currentLanguage()]) +
        '/' +
        this.$unitMeasures['area'][this.$currentLanguage()]
      )
    },
    priceLabel(produto) {
      return (
        this.$t('moeda') +
        '/' +
        (produto?.unidade?.replace('_', ' ') || this.$unitMeasures['volume'])
      )
    },
  },
}
</script>
