<script>
import { mapGetters } from 'vuex'
import ProductOptionsContainer from '@/components/ProductOptions/ProductOptionsContainer'

export default {
  components: {
    ProductOptionsContainer,
  },
  data() {
    return {
      openSuggestions: false,
    }
  },
  computed: {
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('farms', ['currentFarm']),

    diseasesData() {
      return {
        sprayingNumber: this.num_aplicacao,
        primaryTarget: this.doencaPrioritariaId,
        secondaryTarget: this.doencaSecundaria1Id,
        countryId: this.currentFarm?.location?.country?.id,
        cropId: this.currentCrop?.id,
      }
    },
    openSuggestionsBtn() {
      return this.openSuggestions
        ? {
            title: this.$t('products_options.hide_options'),
            short_title: this.$t('products_options.short_options'),
            icon: 'mdi-chevron-up',
          }
        : {
            title: this.$t('products_options.show_options'),
            short_title: this.$t('products_options.short_options'),
            icon: 'mdi-chevron-down',
          }
    },
  },
  methods: {
    toggleSuggestions() {
      this.openSuggestions = !this.openSuggestions
    },
    addMixToApplication({ primary, secondary = null }) {
      const suggestedProducts = []

      suggestedProducts.push({
        unidade: primary.unitType,
        tipo: primary.type,
        id: primary.id,
        preco: '',
        dose: '',
      })

      if (secondary) {
        suggestedProducts.push({
          unidade: secondary.unitType,
          tipo: secondary.type,
          id: secondary.id,
          preco: '',
          dose: '',
        })
      }

      this.selectedProducts = suggestedProducts
      this.updateSelectedProducts()
    },
  },
}
</script>
