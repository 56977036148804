<template>
  <div>
    <v-alert
      v-if="program.total_aplicacoes_sugeridas"
      dense
      text
      color="blue"
      class="body-2 text-center"
    >
      {{ $t('programa.numero_aplicacoes_sugeridas') }}: <br />
      {{
        $t('_to_', [
          program.total_aplicacoes_sugeridas - 1,
          program.total_aplicacoes_sugeridas,
        ])
      }}
    </v-alert>

    <v-alert
      v-if="program.white_mold_total_sprayings > 0"
      dense
      text
      class="body-2 text-center warning-alert"
    >
      {{ whiteMoldSprayingText }}
      {{ $t('programa.white_mold_total_sprayings') }} <br />
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
  computed: {
    whiteMoldSprayingText() {
      const totalSprayings = this.program.white_mold_total_sprayings
      if (totalSprayings == 1) return `${totalSprayings}`
      if (totalSprayings > 1)
        return this.$t('_to_', [totalSprayings - 1, totalSprayings])
      return ''
    },
  },
}
</script>

<style scoped>
.warning-alert {
  color: #f3cd12;
  background-color: #fff2b0;
}
</style>
