import defaultsDeep from 'lodash.defaultsdeep'
import { normalizeCurrency } from '@/utils/formatters'
import storage from './storage'

function ProductPriceStorage(products) {
  this.storageKey = 'last-product-price'
  this.productType = null
  this.productId = null
  this.products = products

  this.setProducts = function (newProducts) {
    this.products = newProducts
  }

  this.formatProducts = function () {
    return this.products.reduce(
      (finalProducts, product) => {
        finalProducts[product.tipo] = {
          ...finalProducts[product.tipo],
          [product.id]: normalizeCurrency(product.preco),
        }
        return finalProducts
      },
      { F: null, A: null }
    )
  }

  this.type = function (type) {
    this.productType = type
    return this
  }

  this.id = function (id) {
    this.productId = id
    return this
  }

  this.getAll = function () {
    return storage(this.storageKey).recover()
  }

  this.get = function () {
    if (!this.productType)
      throw Error('ProductPriceStorage:get miss "productType" property.')

    if (!this.productId)
      throw Error('ProductPriceStorage:get miss "productId" property.')

    const stored = storage(this.storageKey).recover()
    if (stored) {
      const productsByType = stored[this.productType]

      if (productsByType) {
        return productsByType[this.productId] || ''
      }
    }

    return ''
  }

  this.clear = function () {
    storage(this.storageKey).clear()
  }

  this.save = function () {
    const storedValues = this.getAll()

    const mergedProducts = storedValues
      ? defaultsDeep(this.formatProducts(), this.getAll())
      : this.formatProducts()

    return storage(this.storageKey).save(mergedProducts)
  }
}

export default (products) => new ProductPriceStorage(products)
