<template>
  <v-radio-group
    v-model="internalValue"
    :disabled="disabled"
    :required="required"
    :error-messages="errors"
    :row="row"
  >
    <template #label>
      <div>
        <span v-html="title"></span>
        <v-chip
          v-if="withTestLabel"
          style="text-transform: uppercase; margin-left: 10px"
          small
          color="primary"
          >{{ $t('test') }}</v-chip
        >
      </div>
    </template>
    <span v-if="!!description" class="grey--text text--darken-1 my-3">{{
      description
    }}</span>
    <v-radio
      v-for="(option, index) in internalOptions"
      :key="index"
      :value="option.value"
      color="success"
    >
      <template #label>
        {{ option.label }}
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import { disabled, required, errors } from './fieldProperties'

export default {
  props: {
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    row: {
      type: Boolean,
      default: false,
    },
    withTestLabel: {
      type: Boolean,
      default: false,
    },
    disabled,
    required,
    errors,
  },
  data() {
    return {
      internalValue: null,
      internalOptions: [
        {
          label: this.$t('yes'),
          value: true,
        },
        {
          label: this.$t('no'),
          value: false,
        },
      ],
    }
  },
  watch: {
    options: {
      handler(options) {
        if (options?.length) {
          this.internalOptions = options
        }
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler(newValue) {
        this.internalValue = newValue
      },
      immediate: true,
    },
    internalValue(newInternalValue) {
      this.$emit('input', newInternalValue)
    },
  },
}
</script>
