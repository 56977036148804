<script>
import ChartEfficacy from '@/components/ChartBarsHorizontalWithMax'
import TooltipEfficacy from '@/pages/execucao/TooltipEfficacy.vue'

export default {
  components: { ChartEfficacy, TooltipEfficacy },
  props: {
    item: {
      type: Object,

      required: true,
    },
    isSingle: { type: Boolean, required: true },
  },
  computed: {
    tagColor() {
      return this.isSingle ? 'single-color' : 'mix-color'
    },
    chartData() {
      return [
        [],
        this.item?.efficacy ?? [],
        this.item?.diseasesWithNoRegister ?? [
          false,
          false,
          false,
          false,
          false,
        ],
      ]
    },
    hasDiseaseWithNoRegister() {
      return this.item?.diseasesWithNoRegister?.some(
        (disease) => disease === true
      )
    },
    styles() {
      return { width: '260px', height: '110px', display: 'flex' }
    },
  },
  methods: {
    choose(item) {
      this.$emit('choosing', item)
    },
  },
}
</script>

<template>
  <div>
    <v-card class="mx-auto">
      <v-chip class="ma-2" small label text-color="white" :class="tagColor">
        <v-icon small left color="white"> mdi-label </v-icon>
        {{
          isSingle ? $t('products_options.single') : $t('products_options.mix')
        }}
      </v-chip>

      <span class="subtitle-2 text--primary">
        {{ item.name }}
      </span>

      <v-divider></v-divider>

      <v-card-text class="pt-2 pb-0 d-flex justify-space-between align-center">
        <div class="text--primary mb-1">
          {{ $t('products_options.description.potencial_efficacy') }}
        </div>
        <div class="mb-1">
          <tooltip-efficacy
            :without-register="hasDiseaseWithNoRegister"
            is-product-option
          ></tooltip-efficacy>
        </div>
      </v-card-text>

      <chart-efficacy
        v-if="item.efficacy.length"
        :chartdata="chartData"
        :styles="styles"
      ></chart-efficacy>

      <v-divider></v-divider>

      <v-card-text class="pb-0">
        <p class="text--primary">
          {{ $t('products_options.description.active_ingredients') }}
        </p>
        <div>
          <div class="mb-2">
            <div class="text--primary">{{ item.primary.title }}</div>
            {{ item.primary.description }}
          </div>
          <div v-if="!isSingle">
            <div class="text--primary">{{ item.secondary.title }}</div>
            {{ item.secondary.description }}
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn right small dark color="green darken-1" @click="choose(item)">{{
          $t('select')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.single-color {
  background-color: #fb8c00 !important;
}
.mix-color {
  background-color: #88c03f !important;
}
</style>
