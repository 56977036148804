<template>
  <v-tooltip v-if="withoutRegister && isBrazil" top max-width="300">
    <template #activator="{ on }">
      <div v-on="on">
        <v-icon dense class="red--text">mdi-alert-circle</v-icon>
      </div>
    </template>

    <div>
      {{ textInfo }}
    </div>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    withoutRegister: {
      type: Boolean,
      default: false,
    },
    isProductOption: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['isBrazil']),

    textInfo() {
      return this.isProductOption
        ? this.$t('products_options.description.no_register_info')
        : this.$t('eficacia.sem_registro_ajuda')
    },
  },
}
</script>
