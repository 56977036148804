<script>
const WHITE_MOLD_ID = 13

export default {
  data() {
    return {
      isWhiteMoldOnlySpraying: false,
    }
  },
  computed: {
    prioritaryTargetIsWhiteMold() {
      return this.doencaPrioritariaId == WHITE_MOLD_ID
    },

    hasWhiteMold() {
      return this.prioritaryTargetIsWhiteMold && this.doencaSecundaria1Id
    },

    whiteMoldDefensives() {
      return this.defensives.filter((defensive) => defensive.is_white_mold)
    },
  },
  methods: {
    disableTargetsSelectionByRules(targetId) {
      if (!this.doencaPrioritariaId) {
        return false
      }
      return (
        targetId == WHITE_MOLD_ID && this.doencaPrioritariaId !== WHITE_MOLD_ID
      )
    },

    setWhiteMoldOnlySpraying(prioritaryTargetId, secondaryTargetId) {
      this.isWhiteMoldOnlySpraying =
        prioritaryTargetId == WHITE_MOLD_ID && !secondaryTargetId
    },
  },
}
</script>
