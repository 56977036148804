<template>
  <v-card class="mx-auto" max-width="350" tile>
    <v-skeleton-loader
      v-if="loadingPremiumList"
      type="list-item, divider, list-item, divider, list-item, divider,list-item, divider, list-item, divider, list-item, divider"
      class="mx-auto"
    >
    </v-skeleton-loader>
    <v-list
      v-else
      max-height="380"
      :class="[!selectedItem ? 'product-options-list' : '']"
    >
      <v-subheader>{{ $t('products_options.list.title') }}</v-subheader>

      <v-list-item-group
        v-for="item in filterItems"
        :key="item.id"
        :class="['product-options-premium-item', highlight(item.id)]"
        :max="1"
        :value="selectedItemId"
      >
        <v-list-item
          :class="[
            selectedItemId === item.id ? 'v-list-item--active' : '',
            'qa-product-options__premium-item',
          ]"
          @click="selectItem(item)"
        >
          <v-list-item-title>
            <v-chip v-if="selectedItemId === item.id" class="single-color">
              1
            </v-chip>
            {{ item.title }}
          </v-list-item-title>
          <v-list-item-action v-if="selectedItemId === item.id">
            <v-btn icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-skeleton-loader
      v-if="selectedItem && loadingSecondaryList"
      type="list-item, divider, list-item, divider, list-item, divider,list-item, divider, list-item, divider, list-item, divider"
      class="mx-auto"
    >
    </v-skeleton-loader>

    <v-list
      dense
      max-height="267"
      class="product-options-list"
      v-if="!loadingSecondaryList && selectedItem"
    >
      <v-list-item-group
        v-for="secondaryItem in secondaryItems"
        :key="secondaryItem.id"
        :class="[
          'product-options-premium-item',
          highlightSecondary(secondaryItem.id),
        ]"
        dense
        :max="1"
        :value="selectedSecondaryItemId"
      >
        <v-list-item
          :class="[
            selectedSecondaryItemId === secondaryItem.id
              ? 'v-list-item--active'
              : '',
            'qa-product-options__secondary-item',
          ]"
          @click="selectSecondaryItem(secondaryItem)"
        >
          <v-list-item-title>
            <v-chip
              v-if="selectedSecondaryItemId === secondaryItem.id"
              class="mix-color"
              small
            >
              2
            </v-chip>
            {{ secondaryItem.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    premiumItems: {
      type: Array,
      required: true,
    },
    secondaryItems: {
      type: Array,
      required: true,
    },
    loadingPremiumList: {
      type: Boolean,
      required: true,
    },
    loadingSecondaryList: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: null,
      selectedItemId: null,
      selectedSecondaryItem: null,
      selectedSecondaryItemId: null,
    }
  },
  computed: {
    filterItems() {
      if (!this.selectedItemId) return this.premiumItems
      return this.premiumItems.filter(({ id }) => id === this.selectedItemId)
    },
  },

  methods: {
    selectItem(item) {
      const shouldSelectNewItem = this.selectedItemId !== item?.id

      this.selectedItemId = shouldSelectNewItem ? item?.id : null
      this.selectedItem = shouldSelectNewItem ? item : null

      this.selectSecondaryItem(null)

      this.$emit('premium-selected', this.selectedItem)
    },
    selectSecondaryItem(item) {
      const shouldSelectNewItem = this.selectedSecondaryItemId !== item?.id

      this.selectedSecondaryItemId = shouldSelectNewItem ? item?.id : null
      this.selectedSecondaryItem = shouldSelectNewItem ? item : null

      this.$emit('secondary-selected', this.selectedSecondaryItem)
    },
    highlight(id) {
      return this.selectedItemId === id ? 'highlight' : ''
    },
    highlightSecondary(id) {
      return this.selectedSecondaryItemId === id ? 'highlight' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.product-options-list {
  overflow-y: auto;
}
.product-options-premium-item {
  border-bottom: 1px solid #eceff1;
}
.highlight .v-list-item__title {
  z-index: 1;
  font-weight: 700;
}
.highlight {
  .single-color {
    background-color: #fb8c00 !important;
  }
  .mix-color {
    background-color: #88c03f !important;
  }
}
</style>
