<script>
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin.vue'

export default {
  props: {
    produto: { type: Object, required: true },
  },

  mixins: [CropDiseasesMixin],

  computed: {
    targetsAndDoses() {
      return (
        Object.entries(this.produto?.doses).filter(
          ([_, diseaseData]) =>
            !this.ignoredDiseases.includes(diseaseData.target_id)
        ) ?? []
      )
    },
  },
}
</script>

<template>
  <v-tooltip left>
    <template #activator="{ on }">
      <div class="tooltip__operation-dosis text-center" v-on="on">
        <v-icon>mdi-beaker-outline</v-icon>
      </div>
      <div class="text-center justify-center caption d-none d-sm-flex">
        {{ $t('fungicida.doses') }}
      </div>
    </template>
    <div class="tooltip__choose-product" v-if="!produto.doses">
      {{ $t('aplicacao.choose_product') }}
    </div>
    <div v-else>
      <table>
        <thead class="tooltip__operation-dosis-header">
          <tr>
            <td></td>
            <td>{{ $t('fungicida.doses_recomendadas') }}</td>
          </tr>
        </thead>

        <tbody class="tooltip__targets-list">
          <tr
            v-for="([target_code, dose], index) in targetsAndDoses"
            :key="index"
          >
            <td>{{ $t(`diseases.${target_code}`) }}</td>
            <td v-if="dose.dose_recomendada == 999999.0">
              {{ $t('fungicida.sem_registro') }}
            </td>
            <td v-else>
              {{
                $t('_to_', [dose.dose_recomendada, dose.dose_recomendada_max])
              }}
              {{ produto.unidade }}/{{
                $unitMeasures['area'][$currentLanguage()]
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-tooltip>
</template>
