<template>
  <div class="px-6" :key="reloadKey">
    <v-divider></v-divider>
    <v-row v-if="showMissingTargetsMessage">
      <v-alert class="mt-4" icon="mdi-information" prominent text type="info">
        {{ $t('products_options.missing_targets') }}
      </v-alert>
    </v-row>
    <v-row v-else-if="showErrorMessage">
      <v-alert
        class="mt-4"
        icon="mdi-information"
        prominent
        text
        type="warning"
        width="100%"
      >
        {{ $t('products_options.error.loading') }}
      </v-alert>
    </v-row>
    <v-row v-else>
      <v-col md="5">
        <product-options-list
          @premium-selected="selectPremium"
          @secondary-selected="selectSecondary"
          :premium-items="formatPrimary"
          :secondary-items="formatSecondary"
          :loading-premium-list="loadingPremiumList"
          :loading-secondary-list="loadingSecondaryList"
        ></product-options-list>
      </v-col>
      <v-col md="7">
        <product-options-description
          v-if="selectedProductInfoToShow"
          :item="selectedProductInfoToShow"
          :is-single="isSingle"
          @choosing="addMixToApplication"
        ></product-options-description>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductOptionsList from '../ProductOptions/ProductOptionsList'
import ProductOptionsDescription from '../ProductOptions/ProductOptionsDescription'
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [CropDiseasesMixin],
  components: {
    ProductOptionsList,
    ProductOptionsDescription,
  },
  props: {
    diseasesData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      premiumProductSelected: null,
      secondaryProductSelected: null,
      missingSecondaryTargets: true,
      loadingPremiumList: true,
      loadingSecondaryList: true,
      showMissingTargetsMessage: false,
      showErrorMessage: false,
      mixes: {
        primary: [],
        secondary: [],
      },
    }
  },
  watch: {
    diseasesData: {
      handler() {
        this.clearAllData()
        this.initializeProductsOptions()
      },
      immediate: true,
      deep: true,
    },
    async premiumProductSelected() {
      if (this.premiumProductSelected) {
        await this.fetchSecondaryList()
      }
    },
  },
  computed: {
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),

    reloadKey() {
      return `${this.diseasesData.primaryTarget}${this.diseasesData.secondaryTarget}`
    },
    formatPrimary() {
      return this.formatOptions(this.mixes.primary)
    },
    formatSecondary() {
      return this.formatOptions(this.mixes.secondary)
    },
    selectedProductInfoToShow() {
      if (!this.premiumProductSelected) {
        return null
      }
      const formatMixName = (primary, secondary) => {
        if (!secondary?.title) return primary.title
        return `${primary.title} + ${secondary.title}`
      }

      const secondary = this.secondaryProductSelected
        ? {
            id: this.secondaryProductSelected?.id,
            type: this.secondaryProductSelected?.type,
            unitType: this.secondaryProductSelected?.unitType,
            title: this.secondaryProductSelected?.title,
            description: this.secondaryProductSelected?.description,
          }
        : null

      return {
        name: formatMixName(
          this.premiumProductSelected,
          this.secondaryProductSelected
        ),
        efficacy: this.secondaryProductSelected
          ? this.secondaryProductSelected.efficacy
          : this.premiumProductSelected.efficacy,
        diseasesWithNoRegister: this.secondaryProductSelected
          ? this.secondaryProductSelected?.diseasesWithNoRegister
          : this.premiumProductSelected?.diseasesWithNoRegister,
        primary: {
          id: this.premiumProductSelected?.id,
          type: this.premiumProductSelected?.type,
          unitType: this.premiumProductSelected?.unitType,
          title: this.premiumProductSelected?.title,
          description: this.premiumProductSelected?.description,
        },
        secondary,
      }
    },
    isSingle() {
      return !this.secondaryProductSelected
    },
  },
  methods: {
    clearAllData() {
      this.premiumProductSelected = null
      this.secondaryProductSelected = null
      this.missingSecondaryTargets = true
      this.loadingPremiumList = true
      this.loadingSecondaryList = true
      this.showMissingTargetsMessage = false
      this.mixes = {
        primary: [],
        secondary: [],
      }
    },
    initializeProductsOptions() {
      const { primaryTarget, secondaryTarget } = this.diseasesData

      this.showMissingTargetsMessage = !primaryTarget && !secondaryTarget

      this.fetchPremiumList()
    },
    selectPremium(item) {
      this.premiumProductSelected = item
    },
    selectSecondary(item) {
      this.secondaryProductSelected = item
    },
    addMixToApplication(mix) {
      this.$emit('choosing', mix)
    },
    formatOptions(options) {
      const formatActiveIngredients = (activeIngredient) =>
        activeIngredient.map(({ name }) => name.capitalize()).join(', ')

      const formatEfficaciesAndRegisters = (efficacies) => {
        if (!efficacies) return []

        return Object.values(efficacies).reduce(
          (acc, { efficacy, no_register_for_disease }) => {
            acc.efficacy.push(efficacy.potencial)
            acc.diseasesWithNoRegister.push(no_register_for_disease)
            return acc
          },
          { efficacy: [], diseasesWithNoRegister: [] }
        )
      }

      return options.map(({ defensives, efficacies }, index) => {
        const { efficacy, diseasesWithNoRegister } =
          formatEfficaciesAndRegisters(efficacies)

        const chosenDefensive =
          defensives.length === 1 ? defensives[0] : defensives[1]

        const { defensive, unit } = chosenDefensive
        return {
          index,
          id: defensive?.id,
          title: defensive?.name,
          type: defensive?.type,
          unitType: unit,
          description: formatActiveIngredients(defensive?.active_ingredients),
          efficacy,
          diseasesWithNoRegister,
        }
      })
    },
    async fetchPremiumList() {
      try {
        this.showErrorMessage = false
        this.loadingPremiumList = true
        const { primaryTarget } = this.diseasesData

        if (primaryTarget) {
          const { data } = await this.$api.defensiveOptions.get(
            this.diseasesData,
            this.currentSeasonCrop?.id
          )
          const transformedData = this.ignoreSomeProductDiseases(data)
          this.mixes.primary = transformedData
        } else {
          this.mixes.primary = []
        }

        this.loadingPremiumList = false
      } catch (e) {
        this.mixes.primary = []
        this.loadingPremiumList = false
        this.showErrorMessage = true
        this.$root.$emit(
          'notify',
          'error',
          this.$t('products_options.error.loading')
        )
      }
    },
    ignoreSomeProductDiseases(data) {
      return data.map((productoptions) => {
        return {
          ...productoptions,
          efficacies: productoptions.efficacies.filter(
            ({ disease_id }) => !this.ignoredDiseases.includes(disease_id)
          ),
        }
      })
    },
    async fetchSecondaryList() {
      try {
        this.showErrorMessage = false
        this.loadingSecondaryList = true
        const { primaryTarget } = this.diseasesData

        if (primaryTarget) {
          const { data } = await this.$api.defensiveOptions.get(
            {
              ...this.diseasesData,
              mainDefensiveId: this.premiumProductSelected.id,
            },
            this.currentSeasonCrop?.id
          )

          const transformedData = this.ignoreSomeProductDiseases(data)
          this.mixes.secondary = transformedData
        } else {
          this.mixes.secondary = []
        }
        this.loadingSecondaryList = false
      } catch (e) {
        this.mixes.secondary = []
        this.loadingSecondaryList = false
        this.showErrorMessage = true
        this.$root.$emit(
          'notify',
          'error',
          this.$t('products_options.error.loading')
        )
      }
    },
  },
}
</script>
