export const required = {
  type: Boolean,
  default: false,
}

export const errors = {
  type: Array,
  default: () => [],
}

export const disabled = {
  type: Boolean,
  default: false,
}
